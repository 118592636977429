import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import logoutIcon from "../../../../Images/logout.png";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSignOutAlt } from  '@fortawesome/free-solid-svg-icons'
import {
	setLoginToken,
	setGarageDetails,
	setSideBarToggle,
} from "../../../../Redux/Actions/ActionCreators";
import { getGarages, getNonNetworkGarages } from "../../../../Apis/Apis";
import GarageSelect from "../../GarageSelect";
import Select from "../../Input/MultiSelect/MultiSelect-2";
import Toast from "../../Toast/Toast";
import {
	adminUser,
	checkFinanceLevelOne,
	checkIfSpareRetailerLoggedIn,
	isDistributorAdmin,
	isMalaysiaLogin,
	isNonNetowrkUser,
} from "../../../Routes/AdminUsers";
import "./Header.css";
import { checkUserType } from "../../../../Methods";
import { Button } from "rsuite";
import GarageSelectV2 from "../../GarageSelect/garage.select.v2";

const Header = (props) => {
	const NNDropdown = ["/payments/collection-payment", 
		"/reconciliation/non-network-garage-wise",
		"/payments/non-network-retailer-payment-request"];
	const [garage, setGarage] = useState({
		label: "Garages",
		name: "Star Car Care",
		id: null,
		options: [{  }],
	});
	const {
		loginToken,
		garageDetails,
		setLoginToken,
		setGarageDetails,
		cityId,
		// activeSideBarHeader,
	} = props;

	// const { headerName, subHeaderName } = activeSideBarHeader;
	useEffect(() => {
		const fetchData = (async () => {
			const garageDetails = JSON.parse(localStorage.getItem("garageDetails"));
			if (garageDetails && (adminUser() || isMalaysiaLogin())) {
				const [name, id, is_gaas] = [garageDetails.data.name, garageDetails.data.id, garageDetails.data.is_gaas];
				let caller = getGarages;
				
				if(NNDropdown.includes(props?.location?.pathname) || isNonNetowrkUser()){
					caller =  getNonNetworkGarages;
				}
				const garages = await caller(cityId);
				console.log("garages", garages);
				
				
				setGarage({
					...garage,
					options: [{ id: 1001001, name: "All Garages", is_gaas : 0 }, ...garages],
					name,
					id,
					is_gaas,
				});
			}
		})();
	}, [loginToken, cityId, props?.location?.pathname]);

	useEffect(() => {
		// In case of change of garage by other component
		const { garageDetails } = props;
		const garageDetailsLStorage = JSON.parse(localStorage.getItem("garageDetails"));
			
		if(garageDetails && garageDetails.data && (garageDetails.data.id !== garage.id)) {
			setGarage({...garage, ...garageDetails.data})
		}
		if(garageDetailsLStorage && garageDetailsLStorage.data && garageDetailsLStorage.data.id !== garageDetails.data.id) {
			localStorage.setItem("garageDetails", JSON.stringify(garageDetails));
		}
	}, [props.garageDetails, garage])

	const changeGarage = (
		{ 
		id, 
		name, 
		show_new_payment_logic = false,
		new_payment_logic_month = null,
		new_payment_logic_year = null, 
		is_gaas,
		is_accessories = 0 
		}
	) => {
		const garageDeTails = { 
			...garageDetails, 
			data: { 
				id, 
				name, 
				is_accessories,
				show_new_payment_logic,
            	new_payment_logic_month,
            	new_payment_logic_year,
				is_gaas 
			} 
		};	
		setGarage({ ...garage, id, name, is_gaas });
		setGarageDetails(garageDeTails);
		localStorage.setItem("garageDetails", JSON.stringify(garageDeTails));
	};

	const logOut = () => {
		localStorage.clear();
		setLoginToken(null);
		setGarageDetails({});
	};

	// const setSideBarToggler = () => {
	// 	setIsSideBarOpen(true);
	// 	props.setSideBarToggle(true);
	// };

	const allGarages = (pathname) => {
		if (NNDropdown.includes(pathname) || isNonNetowrkUser() || checkUserType() || isMalaysiaLogin()) {
			return <Select
			labelKey={"name"}
			statusKey={"status"}
			subitemLabelKeyArray={["city_name", "id"]}
			selectedOptions={garage.id}
			options={garage.options}
			onChange={(value) => changeGarage(value)}
			placeholder={`${garage.label}`}
			checkbox_id="id"
			disabledItems={[]}
			key={1}
		/>;
		} else if(garage?.options?.length > 1) {
			return (
				<GarageSelectV2
					labelKey={"name"}
					statusKey={"status"}
					subitemLabelKeyArray={["city_name", "id"]}
					selectedOptions={garage?.id}
					options={garage?.options}
					onSelect={changeGarage}
					placeholder={`${garage?.label}`}
					checkbox_id="id"
					disabledItems={[]}
					key={1}/>
				// <GarageSelect
				// 	labelKey={"name"}
				// 	statusKey={"status"}
				// 	subitemLabelKeyArray={["city_name", "id"]}
				// 	selectedOptions={garage.id}
				// 	options={garage.options}
				// 	onChange={(value) => changeGarage(value)}
				// 	placeholder={`${garage.label}`}
				// 	checkbox_id="id"
				// 	disabledItems={[]}
				// 	key={1}
				// />
			);
		}
	};
	const fetchedLocalStorageGarageDetails = JSON.parse(
		localStorage.getItem("garageDetails")
	);
	const spareGarageData =
		fetchedLocalStorageGarageDetails &&
		fetchedLocalStorageGarageDetails.retailer_data;
	const sparesRetailerName =
		(checkIfSpareRetailerLoggedIn() &&
			spareGarageData &&
			spareGarageData.retailer_name) ||
		"";

	const exemptGarageFilterFromPath = [ 
		"/discounts", 
		"/do-amount", 
		"/gom_inventory",
	  	"/reconciliation/retailer-payments",
		"/reconciliation/order-tracking",
		"/payments/workshop-pl-entry",
		"/dashboard/gom-to-distributor",
		"/reconciliation/pl-return",
		"/reconciliation/summary",
		"/workshop-payments",
		"/distributor-payments",
		"/retailer-payments",
		"/playstore-orders",
		"/order-tracking",
		"/pl-return"];

	let isDistributorAccess = isDistributorAdmin();
	const allowTitles = exemptGarageFilterFromPath;
	const getTitle = (path) => path.split('/').pop().replace("_", " ").replace("-", " ");


	if (loginToken) {
		return (
			<div className="header-container projectFontFamily">
				<div className="header">
					{ !isDistributorAccess && !exemptGarageFilterFromPath.includes(props.location.pathname) && allGarages(props?.location?.pathname)} 
					<div className="header-element">
						{/* <div>{garage.name}</div> */}
						{allowTitles.includes(props?.location?.pathname) && 
						<div style={{textTransform: "capitalize"}}>{getTitle(props?.location?.pathname)}</div>}
						<div
							onClick={() => props.setSideBarToggle(true)}
							className="hamburger-menu"
						>
							<div />
							<div />
							<div />
						</div>
						{
							!isDistributorAccess && !exemptGarageFilterFromPath.includes(props.location.pathname) && <>
								<div>
									{sparesRetailerName ||
									(garageDetails &&
										garageDetails.data &&
										garageDetails.data.name)}
								</div>
							</>
						}
						{
							isDistributorAccess && <div>
								Distributor Dashboard
							</div>
						}
						
						{/* <div className='location'>({garageDetails && garageDetails.data && garageDetails.data.location})</div> */}
					</div>
				</div>
				{/* <FontAwesomeIcon icon={faSignOutAlt} className='log-out-icon' onClick={logOut}/> */}
				<div className="log-out" onClick={logOut}>
					<Button appearance="primary" color="red" className="log-out-text">Logout</Button>
					{/* <img src={logoutIcon} className="log-out-icon" /> */}
				</div>

				<Toast />
			</div>
		);
	} else {
		return null;
	}
};

export default withRouter(
	connect(
		(state) => {
			const { loginToken, garageDetails, cityId, activeSideBarHeader } = state;
			return {
				loginToken,
				garageDetails,
				cityId,
				activeSideBarHeader,
			};
		},
		{
			setLoginToken,
			setGarageDetails,
			setSideBarToggle,
		}
	)(Header)
);
